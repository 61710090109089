import React from "react";
import useTheme from "../../context/themcontext";
function ExpertiseCard({ heading, description, number, svg }) {
  const { themeMode } = useTheme();
  const isDark = themeMode === "dark" ? true : false;

  return (
    <div 
      
      className={`expertise-card h-fit min-h-fit   rounded-lg   w-full lg:w-1/3 lg:h-[50vh]  max-h-[550px] flex-col flex py-6 px-8 ${
        isDark ? "bg-[#131313] outline-[#ae5eeb]" : "bg-[#dadada] outline-[#560c8e]"
      } ${number === 2 ? "outline outline-4 " : "" } 
           ` }


           
      style={{
        boxShadow: isDark
          ? " rgba(105, 10, 176, 0.7) 0px 0px 15px"
          : " rgba(0, 0, 0, 0.5) 0px 3px 15px, rgba(0, 0, 0, 0.27) 0px 10px 10px",
      }}
    >
      <div className="icon-number py-2 flex justify-between items-center  ">
        <div className="card-icon h-full flex items-center justify-center ">
          {svg}
        </div>
        <p
          className="card-number text-5xl font-mono "
          style={{
            color: isDark ? "#560c8e" : "#4B0D7B",
            textShadow:
              "-1px -1px 0 #85cebd, 1px -1px 0 #85cebd, -1px 1px 0 #85cebd, 1px 1px 0 #85cebd",
          }}
        >
          0{number}.
        </p>
      </div>
      <div
        className={`card-content space-y-7 mt-2 ${
          isDark ? "text-white" : "text-black"
        }  `}
      >
        <h1 className="heading mt-3 font-bold md::text-3xl  ">{heading}</h1>

        <p className="ddescription">{description}</p>
      </div>
    </div>
  );
}

export default ExpertiseCard;
