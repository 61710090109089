import "./App.css";

import React from "react";
import ExpertisePage from "./components/Expertise/ExpertisePage.jsx";
import Introductionpage from "./components/Introduction/Introductionpage.jsx";
import LandingPage from "./components/Landing/LandingPage.jsx";
import NavBar from "./components/layouts/Navbar.jsx";
import { ThemeContextProvider } from "./context/themcontext.js";
import Offerpage from "./components/WeOffer/OfferPage.jsx";
import Footer from "./components/layouts/Footer.jsx";
import PricingPage from "./components/pricing/PricingPage.jsx";
import ContactUsPage from "./components/Contactus/ContactUsPage.jsx";
function App() {
  const [themeMode, setthemeMode] = React.useState("dark");

  const darkTheme = () => {
    setthemeMode("dark");
  };

  const lightTheme = () => {
    setthemeMode("light");
  };

  React.useEffect(() => {
    document.querySelector("html").classList.remove("dark", "light");
    document.querySelector("html").classList.add(themeMode);
  }, [themeMode]);

  const isDark = themeMode === "dark";
  return (
    <>
      <ThemeContextProvider value={{ darkTheme, lightTheme, themeMode }}>
        <main id="home" className={`relative ${isDark ? "bg-[#131313]" : "bg-[#dadada]"}`}>
          <NavBar />
          <LandingPage />
          <ExpertisePage />
          <Introductionpage/>
          <Offerpage/>

          <PricingPage/>
          <ContactUsPage/>
          <Footer/>
        </main>
      </ThemeContextProvider>
    </>
  );
}

export default App;
