import React from "react";
import useTheme from "../../context/themcontext";

function PricingPage() {
  const pricingOptions = [
    {
      heading: "Basic Meal Plan",
      subHeading: "Enjoy a daily meal with our basic plan.",
      price: "9.99",
      offers: [
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
      ],
    },
    {
      heading: "Basic Meal Plan",
      subHeading: "Enjoy a daily meal with our basic plan.",
      price: "9.99",
      offers: [
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
      ],
    },
    {
      heading: "Basic Meal Plan",
      subHeading: "Enjoy a daily meal with our basic plan.",
      price: "9.99",
      offers: [
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: false,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
        {
          offer: "Breakfast",
          isOffer: true,
        },
      ],
    },
  ];

  const { themeMode } = useTheme();

  const isDark = themeMode === "dark";
  return (
    <div id="pricing" className="text-white md:mx-24 ">
      <br />
      <br /> <br />
      <section class="px-8 py-24">
        <div class={`container mx-auto text-center ${isDark?"text-[#ae5eeb]":"text-[#4B0D7B]"} `}>
          <h2 class="block antialiased tracking-normal font-sans text-4xl font-semibold leading-[1.3] text-blue-gray-900 mb-4">
            Pricing
          </h2>
          <p class="block antialiased font-sans text-base leading-relaxed text-inherit mb-8 font-normal ">
            Check out our affordable pricing options for delicious meals.
          </p>
        </div>
        <div class="mt-24 ">
          <div class="grid gap-8 md:grid-cols-2 lg:grid-cols-3 ">
            {pricingOptions.map((e, idx) => {
              return (
                <div
                  class={`relative flex flex-col bg-clip-border rounded-xl  ${isDark ? "bg-[#131313] text-white outline-[#ae5eeb]" : "bg-[#dadada] text-black outline-[#4B0D7B]"}
                    
                    
                    
                     outline outline-4  ${
                    idx === 1 ? "scale-110 " : ""
                  }`
                
                
                
                }




                  style={{
                    boxShadow: isDark
                      ? " rgba(105, 10, 176, 0.7) 0px 0px 15px"
                      : " rgba(0, 0, 0, 0.5) 0px 3px 15px, rgba(0, 0, 0, 0.27) 0px 10px 10px",
                  }}
                >
                  <div class="relative bg-clip-border mt-4 mx-4 rounded-xl overflow-hidden bg-transparent  shadow-none !m-0 p-6">
                    <h5 class="block antialiased tracking-normal font-sans text-xl font-semibold leading-snug text-blue-gray-900 capitalize">
                      {e.heading}
                    </h5>
                    <p class="block antialiased font-sans text-sm leading-normal text-inherit font-normal ">
                      {e.subHeading}
                    </p>
                    <h3 class="antialiased tracking-normal font-sans text-3xl font-semibold leading-snug text-blue-gray-900 flex gap-1 mt-4">
                      ${e.price}
                    
                    </h3>
                  </div>
                  <div class={`p-6 border-t border-blue-gray-50 ${isDark?"border-[#ae5eeb]":"border-[#4B0D7B]"} `}>
                    <ul class="flex flex-col gap-3">
                      {e.offers.map((o) => {
                        return (
                          <li class="flex items-center gap-3 ">
                            {o.isOffer ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="3"
                                stroke="#84CDBB"
                                aria-hidden="true"
                                class="h-4 w-4 text-blue-gray-900"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M4.5 12.75l6 6 9-13.5"
                                ></path>
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                stroke="red"
                                viewBox="0 0 24 24"
                                stroke-width="3"
                               
                                aria-hidden="true"
                                class="h-4 w-4 text-blue-gray-900"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                ></path>
                              </svg>
                            )}
                            <p class="block antialiased font-sans text-sm leading-normal font-normal text-inherit">
                              {o.offer}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                    <button
                      class={`align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-2 px-4 rounded-lg border ${isDark?"border-[#ae5eeb] text-[#ae5eeb]":"border-[#4B0D7B] text-[#4B0D7B]"} hover:opacity-75 focus:ring focus:ring-green-200 active:opacity-[0.85] block w-full mt-6`}
                      type="button"
                    >
                      buy now
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default PricingPage;
