import React from "react";
import useTheme from "../../context/themcontext";

function OfferCard({ idx, heading, description, svg }) {
  const { themeMode } = useTheme();
  const isDark = themeMode === "dark" ? true : false;

  return (
    <div
      className={`Offer-card h-fit min-h-[500px]   rounded-lg  w-full max-w-[370px] md:mx-6  lg:h-[50vh]  max-h-[550px] flex-col flex py-2 my-3 px-8 ${
        isDark
          // ? "bg-[#131313] outline-[#DADADA]"
          // : "bg-[#dadada] outline-[#3E0F67]"
           ? "bg-[#131313] outline-[#9040cd]"
          : "bg-[#dadada] outline-[#560C8E]"
      } ${[1,3,5].includes(idx) ? "outline outline-4 " : ""} 
           `}
      style={{
        boxShadow: isDark
          ? " rgba(105, 10, 176, 0.7) 0px 0px 15px"
          : " rgba(0, 0, 0, 0.5) 0px 3px 15px, rgba(0, 0, 0, 0.27) 0px 10px 10px",
      }}
    >
      <div className="heading-icon flex flex-col space-y-3  justify-between items-center">
        <div className="icon ">{svg}</div>
        <div className="heading font-bold font-sans">{heading} </div>
      </div>

      <div className="card-description text-[16px] py-6 text-center">
        {description}
      </div>
    </div>
  );
}

export default OfferCard;
