import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import React, { useEffect, useState } from "react";
import useTheme from "../../context/themcontext";
import "./landingpage.css";

function LandingPage() {
  const {  themeMode } = useTheme();
 

  const [init, setInit] = useState(false);
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <>
      <div
        className={`relative landing-page h-screen max-w-screen text-white overflow-hidden  `}
      >
        <div
          className={`landing-content-container  absolute w-full left-0  flex justify-center 
          h-screen z-10 items-center text-white `}
        >
          <div className="landing-content-box relative   h-fit w-full   justify-center items-center ">
            <div className="digital-agency-heading  h-fit  w-full ">
              <div
                className="background-triangle absolute h-full w-full -top-5
              "
              />
              <div className="background-shadow-box absolute h-[100%] min-h-[30vh] w-[96%] xl:w-[60%]  top-0 left-[50%] -translate-x-[50%]"></div>

              <div className="  md:space-y-3 space-y-2">
                <p className="text-center lg:text-4xl text-4xl font-bold ">
                  DIGITAL AGENCY BUSINESS
                </p>
                <p className="text-center font-mono lg:text-3xl text-xl">
                  Best Digital Agency For Your Business
                </p>
                <p className="text-desc w-full text-base text-center pt-4 ">
                  We are dedicated to transforming businesses through strategic
                  digital solutions that captivate audiences and drive success.
                </p>
              </div>
            </div>

            <div className="landing-page-btns mt-24 md:mt-18  xl:mt-24 w-full flex flex-col sm:space-x-5 sm:flex-row space-y-7 sm:space-y-0 justify-center items-center">
              <button className="discover-btn uppercase md:text-base text-xl  px-[4rem] md:px-9 rounded-full md:py-4 py-5">Discover More</button>
              <button className="hire-btn uppercase  md:text-base text-xl px-[4rem] md:px-10 md:py-4 py-5 rounded-full  bg-black z-40">Hire us now</button>
            </div>

          </div>

        </div>
           
          

        {init && (
          <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={{
              background: {
                color: {
                  value: "#101010",
                },
              },
              style: {
                position: "absolute",
                top: "0",
                height: "100vh",
                opacity: "01",
              },

              fpsLimit: 120,
              interactivity: {
                events: {
                  onClick: {
                    enable: true,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true, // ////////////////////////////////////
                },
                modes: {
                  push: {
                    quantity: 0,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                },
              },
              particles: {
                color: {
                  value: "#ffffff",
                },
                links: {
                  color: "#ffffff",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outModes: {
                    default: "bounce",
                  },
                  random: false,
                  speed: 4,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 800,
                  },
                  value: 80,
                },
                opacity: {
                  value: 0.8,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: { min: 2, max: 5 },
                },
              },
              detectRetina: true,
            }}
          />
        )}
      </div>
    </>
  );
}

export default LandingPage;

