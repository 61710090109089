import React from "react";
import usetheme from "../../context/themcontext";
import "./Introductionpage.css";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function Introductionpage() {
  const description =
    "At Digi Leopard, we are a passionate team of creative professionals who thrive on helping businesses thrive in the digital realm. With our expertise in web design, development, and digital marketing, we empower our clients to reach new heights and achieve their digital goals.At Digi Leopard, we are a passionate team of creative professionals who thrive on helping businesses thrive in the digital realm. With our expertise in web design, development, and digital marketing, we empower our clients to reach new heights and achieve their digital goals.";

  const points = [
    "Creative Solutions",
    "Data-Driven Approach",
    "Seamless User Experiences",
    "Strategic Digital Marketing",
    "Collaborative Partnership",
  ];

  const { themeMode } = usetheme();

  const isDark = themeMode === "dark";


  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(useGSAP);


  useGSAP(() => {
   

    gsap.to("#animation-line-intro-page-down", {
      y: "100%",
      scrollTrigger: {
        trigger: "#animation-line-intro-page-down",
        scroller: "body",
        start: "top 50%",
        end: "top 0%",
        scrub: true,

        
      },
    }); 
    
    gsap.from("#aboutus", {
      opacity:0,
      scale:0.5,
      y:"-20%",
      duration:0.6, 
      scrollTrigger: {
        trigger: "#animation-for-aboutus",
        scroller: "body",
        start: "top 10%",
        end: "top 0%",
        // scrub: true,
        // markers:true,
        
      },
    }); 
    
    
  });


  return (
    <div
      id="aboutus"
      className=" flex flex-col"
    >
      <div className={`main-page  ${
        isDark ? "text-white" : "text-black "
      }  min-h-fit   p-2 font-sans w-full lg:flex pt-14`}>
        <div className="intro-left hidden lg:flex w-1/2 h-full  flex-col mx-auto ">
          <div className="2-images flex mx-auto w-full justify-center ">
            <img
              className="h-[300px] w-[250px] "
              src={`${process.env.PUBLIC_URL}/images/mandiscussing.jpg`}
              alt="businessimage"
            />
            <img
              className=" w-[47%] -translate-x-3 translate-y-2 "
              src={`${process.env.PUBLIC_URL}/images/phone.jpg`}
              alt="businessimage"
            />{" "}
          </div>

          <img
            className="h-[500px] w-[500px] mx-auto -translate-y-20"
            src={`${process.env.PUBLIC_URL}/images/computer.jpg`}
            alt="businessimage"
          />
        </div>

        <div className="relative intro-right lg:w-1/2 h-full">
          <p
            className={` text-center md:text-start font-bold underline ${
              isDark ? "text-[#85CEBD]" : "text-purple-500"
            }`}
          >
            About Us
          </p>
          <p className="intro-heading text-2xl text-center sm:text-3xl lg:text-5xl font-bold py-4">
            Introduction About Our <br className="hidden lg:flex" /> Digital
            <p className="block text-center md:inline">
              {" "}
              Marketing <br className="hidden lg:flex" /> Agency
            </p>
          </p>
          <div
            className="gradient-line my-3"
            style={{
              width: "90%",
              margin: "20px auto",
              height: "2px",
              background: `linear-gradient(to right,  ${
                isDark ? "black" : "#dadada"
              }, rgb(128, 0, 128), ${isDark ? "black" : "#dadada"})`,

              boxShadow: "rgba(105, 10, 176, 0.7) 0px 0px 15px",
            }}
          />

          <div className="intro-content py-5 ">
            <p className="intro-description px-4 pb-5 lg:pb-9 font-sans text-[16px]">
              {description}
            </p>

            <div className="experiance-points py-5 lg:py-9 lg:flex justify-between">
              <div className="experiance-box bg-[#560c8e] w-[96%] lg:w-[30%] mx-1 rounded-2xl flex flex-col items-center justify-center  pb-3 text-white ">
                <p className=" text-[55px] font-bold translate-y-2">5+</p>
                <p>YEARS OF </p>
                <p>EXPERIENCE</p>
              </div>

              <div className="points py-5 lg:w-[60%]">
                {points.map((p, i) => (
                  <p key={i} className="intro-point px-1 text-[16px]">
                    {p}
                  </p>
                ))}
              </div>
            </div>

            <div className="button w-full flex justify-center">
              <button className="more-about-us  text-white  px-[35px] md:px-10 w-[200px]   md:py-4 py-4 rounded-full my-3 mx-auto bg-black z-40  ">
                More About US
              </button>
            </div>
          </div>
        </div>

        <div className="intro-images-sm md:hidden py-8  flex flex-col items-center">
          <img
            className="h-[43.3vmax] w-[33.3vmax] "
            src={`${process.env.PUBLIC_URL}/images/mandiscussing.jpg`}
            alt="businessimage"
          />
          <img
            className="h-[43.3vmax] w-[33.3vmax] "
            src={`${process.env.PUBLIC_URL}/images/phone.jpg`}
            alt="businessimage"
          />{" "}
          <img
            className="h-[43.3vmax] w-[33.3vmax] "
            src={`${process.env.PUBLIC_URL}/images/computer.jpg`}
            alt="businessimage"
          />
        </div>

        <div className="intro-images-md   py-10  hidden md:flex lg:hidden  flex-col mx-auto w-fit">
          <div className="2-images flex">
            <img
              className="h-[43.3vmax] w-[47vw] "
              src={`${process.env.PUBLIC_URL}/images/mandiscussing.jpg`}
              alt="businessimage"
            />
            <img
              className="h-[43.3vmax] w-[47vw] -translate-x-3 translate-y-2 "
              src={`${process.env.PUBLIC_URL}/images/phone.jpg`}
              alt="businessimage"
            />{" "}
          </div>

          <img
            className="h-[43.3vmax] w-[75vw] mx-auto -translate-y-20"
            src={`${process.env.PUBLIC_URL}/images/computer.jpg`}
            alt="businessimage"
          />
        </div>
      </div>


      <div
        className="animation-line-intro-page-down relative overflow-hidden "
        style={{
          width: "3px",
          borderRadius:"70%",
          margin: "30px  auto 30px auto",
          height: "40vh",
          background: "purple",

          // boxShadow: "rgba(105, 10, 176, 0.3) 0px 0px 5px",
        }}
      >
        <div
          id="animation-line-intro-page-down"
          className={`animationline w-full h-full absolute  ${
            isDark ? "bg-[#242424]" : "bg-[#c0bdbd]"
          }`}
        ></div>
      </div>
    </div>
  );
}

export default Introductionpage;
