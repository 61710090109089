import { useEffect, useState } from "react";
import "./navbar.css";
import useTheme from "../../context/themcontext.js";
import { Link } from "react-scroll";
function Navbar() {
  const { darkTheme, lightTheme, themeMode } = useTheme();

  const [isNavBarOpen, setNavBarOpen] = useState(false);

  const isDark = themeMode === "dark" ? true : false;
  const handleMenuBtn = () => {
    setNavBarOpen((prevIsNavBarOpen) => !prevIsNavBarOpen);
  };

  const handleTheme = () => {
    if (isDark) {
      lightTheme();
    } else {
      darkTheme();
    }
  };

  useEffect(() => {
    let lastScrollTop = 0;
    const navbar = document.getElementById("nav-bar");
    const threshold = 70;
    let scrollDiff = 0;

    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollChange = scrollTop - lastScrollTop;

      // Accumulate the scroll difference
      scrollDiff += scrollChange;

      // If scrolled more than the threshold, hide or show the navbar
      if (scrollDiff > threshold) {
        navbar.style.top = "-100%";
        scrollDiff = 0; // Reset scroll difference after hiding
      } else if (scrollDiff < -threshold) {
        navbar.style.top = "0%";
        scrollDiff = 0; // Reset scroll difference after showing
      }

      lastScrollTop = scrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <div
      id="nav-bar"
      className={`fixed top-0 z-[999] lg:px-12 w-full  nav-bar flex   justify-between   px-4 py-4`}
      // style={{backgroundColor:"rgba(8, 37, 104, 0.3)"}}
    >
      <Link
        to={"home"}
        spy={true}
        activeClass="active"
        smooth={true}
        duration={800}
        className=" text-white cursor-pointer  logo  font-bold text-3xl"
      >
        XENITH
      </Link>

      <div className={`btn  flex justify-center items-center gap-3 `}>
        <div
          onClick={handleMenuBtn}
          className={` open-menu-button  cursor-pointer md:hidden w-[30px] flex flex-col gap-2 ${
            isNavBarOpen ? "hidden" : "flex"
          } `}
        >
          <div className="linee  border-t-[3px] border-white"></div>
          <div className="linee  border-t-[3px] border-white"></div>
          <div className="linee  border-t-[3px] border-white"></div>
        </div>

        <div
          onClick={handleMenuBtn}
          className={`close-menu-button cross-container h-8 flex justify-center items-center cursor-pointer md:hidden w-[30px]  ${
            isNavBarOpen ? "block" : "hidden"
          }   md:hidden w-[15px] `}
        >
          <div className="line line1 bg-white"></div>
          <div className="line line2 bg-white"></div>
        </div>

        <div
          className="swich-mode-btn flex md:hidden justify-center "
          onClick={handleTheme}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`lucide lucide-moon ${!isDark ? "hidden" : "flex"}`}
            color="white"
          >
            <path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`lucide lucide-sun-moon ${!isDark ? "flex" : "hidden"} `}
            color="white"
          >
            <path d="M12 8a2.83 2.83 0 0 0 4 4 4 4 0 1 1-4-4" />
            <path d="M12 2v2" />
            <path d="M12 20v2" />
            <path d="m4.9 4.9 1.4 1.4" />
            <path d="m17.7 17.7 1.4 1.4" />
            <path d="M2 12h2" />
            <path d="M20 12h2" />
            <path d="m6.3 17.7-1.4 1.4" />
            <path d="m19.1 4.9-1.4 1.4" />
          </svg>
        </div>
      </div>

      <div
        className={`mobile-nav-menu left-0 w-full absolute  md:hidden mt-12 pt-12 pb-24  gap-4   ${
          isDark
            ? "bg-black text-white border-zinc-100"
            : "bg-black text-white border-zinc-100"
        } transform transition-transform duration-500 ${
          isNavBarOpen ? "translate-y-0" : "-translate-y-[150%]"
        } md:hidden overflow-hidden flex flex-col py-2 border-t-[1px]  w-[90%]`}
      >
        {["Home", "About us", "Pricing", "Portfolio", "contact us"].map(
          (e, idx) => (
            <div
              key={idx}
              className="group overflow-hidden  py-[2px] relative w-fit px-4"
            >
              <Link
                to={e.split(" ").join("").toLowerCase()}
                spy={true}
                activeClass="active"
                smooth={true}
                duration={300}
                key={idx + 200}
               onClick={handleMenuBtn}
                className="relative uppercase text-4xl font-sans font-semibold leading-none tracking-tighter "
              >
                {e}

                <span
                  className={`span1  transition-transform duration-300 ease-in-out    absolute w-full h-[4px] bg-white bottom-0 left-0`}
                ></span>

                <span
                  className={`span2  transition-transform duration-300 ease-in-out    absolute w-full  h-[4px] bg-white bottom-0 left-0`}
                ></span>
              </Link>
            </div>
          )
        )}
      </div>

      <div
        className={` desktop-options font-light gap-3 font-['NeueMontreal'] h-[35px] md:flex  hidden   `}
      >
        {["Home", "About us", "Pricing", "Portfolio", "contact us"].map(
          (o, idx) => {
           

            return (
              <div
               
                key={idx + 100}
                className={`option-box relative ${
                  idx === 4 ? "ml-48" : "ml-6"
                }  min-w-fit  text-[18px] overflow-hidden `}
              >
                <div
                  className={`up-down-box  ${
                    isDark ? "text-white" : "text-white"
                  }  relative h-full  cursor-pointer flex justify-center   translate-y-[50%]  flex-col  transition-transform duration-300`}
                >
                  <Link
                    to={o.split(" ").join("").toLowerCase()}
                    spy={true}
                    activeClass="active"
                    smooth={true}
                    // offset={-10}
                    duration={300}
                    key={idx}
                    href={`#${o}`}
                  >
                    {o}
                  </Link>
                  <Link
                    to={o.split(" ").join("").toLowerCase()}
                    spy={true}
                    activeClass="active"
                    smooth={true}
                    // offset={-10}
                    duration={300}
                    key={idx + 200}
                    href={`#${o}`}
                  >
                    {o}
                  </Link>
                </div>
                <span
                  className={`absolute  bg-white bottom-0 -translate-x-[110%] transition-transform duration-300 ease-in-out   w-full h-[2px] `}
                ></span>
              </div>
            );
          }
        )}

        <div
          className="swich-mode-btn mt-2 mx-2  flex justify-center "
          onClick={handleTheme}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`lucide lucide-moon ${!isDark ? "hidden" : "flex"}`}
            color="white"
          >
            <path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`lucide lucide-sun-moon ${!isDark ? "flex" : "hidden"} `}
            color="white"
          >
            <path d="M12 8a2.83 2.83 0 0 0 4 4 4 4 0 1 1-4-4" />
            <path d="M12 2v2" />
            <path d="M12 20v2" />
            <path d="m4.9 4.9 1.4 1.4" />
            <path d="m17.7 17.7 1.4 1.4" />
            <path d="M2 12h2" />
            <path d="M20 12h2" />
            <path d="m6.3 17.7-1.4 1.4" />
            <path d="m19.1 4.9-1.4 1.4" />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
