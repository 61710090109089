import React from "react";
import useTheme from "../../context/themcontext";
import ExpertiseCard from "./ExpertiseCard";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function ExpertisePage() {
  const { themeMode } = useTheme();
  const isDark = themeMode === "dark" ? true : false;
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(useGSAP);
  const cardsoptions = [
    {
      heading: "SEO Experts",
      description:
        "As SEO experts, we leverage our deep knowledge and proven strategies to optimize your website, increase organic visibility, and drive targeted traffic to help you achieve higher search engine rankings and maximize your online presence.",
      svg: (
        <svg
          fill={isDark ? "#832ac7" : "#4B0D7B"}
          className="cf-icon-svg h-16  "
          viewBox="-1 0 19 19"
          xmlns="http://www.w3.org/2000/svg"
          stroke="rgba(27, 22, 60)"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zm-2.463 3.307-1.762-3.354a.647.647 0 0 0-.52-.314h-.48a7.274 7.274 0 0 1-.687.802 4.235 4.235 0 0 0-1.058 1.54.925.925 0 0 1-1.755 0 4.239 4.239 0 0 0-1.059-1.54 7.24 7.24 0 0 1-.686-.802H5.28a.647.647 0 0 0-.52.314L2.997 12.89a.197.197 0 0 0 .19.313h10.578a.197.197 0 0 0 .19-.313zM6.12 7.222c0 1.97 1.624 2.155 2.316 4.074a.136.136 0 0 0 .266 0c.692-1.919 2.316-2.104 2.316-4.074a2.449 2.449 0 1 0-4.898 0zM8.568 8.26A1.03 1.03 0 0 1 7.54 7.231v-.025a1.03 1.03 0 1 1 2.058 0v.025a1.03 1.03 0 0 1-1.029 1.03z"></path>
          </g>
        </svg>
      ),
    },
    {
      heading: "Digital Media Marketing",
      description:
        "With our expertise in digital media marketing, we create compelling and impactful campaigns across various platforms, ensuring maximum reach, engagement, and conversions for your brand in the ever-evolving digital landscape.",
      svg: (
        <svg
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
          stroke={isDark ? "#000000" : "#DADADA"}
          fill={isDark ? "#832ac7" : "#4B0D7B"}
          className="cf-icon-svg h-16  "
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <defs>
              {" "}
              <style>
                .cls-1fill:#dbe9fa;.cls-2fill:#d9dce1;.cls-3fill:#ffffff;.cls-4fill:#52acf9;
              </style>{" "}
            </defs>{" "}
            <g data-name=" performance" id="_performance">
              {" "}
              <circle className="cls-1" cx="16" cy="16" r="16"></circle>{" "}
              <rect className="cls-2" height="14" width="6" x="2" y="16"></rect>{" "}
              <rect
                className="cls-3"
                height="19"
                width="6"
                x="11"
                y="11"
              ></rect>{" "}
              <polygon
                className="cls-4"
                points="23 1.4 16.92 9 20 9 20 30 26 30 26 9 29.08 9 23 1.4"
              ></polygon>{" "}
              <path d="M2,30H8V16H2ZM4,18H6V28H4Zm7,12h6V11H11Zm2-17h2V28H13ZM23,1.4,16.92,9H20V30h6V9h3.08ZM24,7V28H22V7h-.92L23,4.6,24.92,7Z"></path>{" "}
            </g>{" "}
          </g>
        </svg>
      ),
    },
    {
      heading: "Website Design And Development",
      description:
        "With our expertise in digital media marketing, we create compelling and impactful campaigns across various platforms, ensuring maximum reach, engagement, and conversions for your brand in the ever-evolving digital landscape.",
      svg: (
        <svg
          className="cf-icon-svg h-16   "
          stroke={isDark ? "#832ac7" : "#4B0D7B"}
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 32 32"
          xmlSpace="preserve"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              id="design-and-development--02"
              d="M29,2.5C29,2.776,28.776,3,28.5,3S28,2.776,28,2.5S28.224,2,28.5,2S29,2.224,29,2.5z M26.5,2C26.224,2,26,2.224,26,2.5S26.224,3,26.5,3S27,2.776,27,2.5S26.776,2,26.5,2z M24.5,2C24.224,2,24,2.224,24,2.5 S24.224,3,24.5,3S25,2.776,25,2.5S24.776,2,24.5,2z M10,14.36H4v-0.72h6V14.36z M14,11.36H4v-0.72h10V11.36z M14,8.36H4V7.64h10 V8.36z M31,31.36H1v-0.72h30V31.36z M31,28.36H1v-0.72h30V28.36z M31,25.36H1v-0.72h30V25.36z M31,22.36H1 c-0.199,0-0.36-0.161-0.36-0.36V1c0-0.199,0.161-0.36,0.36-0.36h30c0.199,0,0.36,0.161,0.36,0.36v21 C31.36,22.199,31.199,22.36,31,22.36z M1.36,21.64h29.28V4.36H1.36V21.64z M1.36,3.64h29.28V1.36H1.36V3.64z M24,15.36 c-2.404,0-4.36-1.956-4.36-4.36S21.596,6.64,24,6.64s4.36,1.956,4.36,4.36S26.404,15.36,24,15.36z M22.493,14.313 c0.46,0.209,0.97,0.327,1.507,0.327c2.007,0,3.64-1.633,3.64-3.64c0-0.539-0.117-1.051-0.328-1.511l-3.048,1.774L22.493,14.313z M20.688,9.489c-0.21,0.46-0.328,0.972-0.328,1.511c0,1.213,0.596,2.29,1.511,2.951l1.637-2.821L20.688,9.489z M21.052,8.867 L24,10.583l2.948-1.716C26.286,7.955,25.211,7.36,24,7.36S21.714,7.955,21.052,8.867z"
            ></path>
            <rect
              id="_Transparent_Rectangle"
              style={{ fill: "none" }}
              width="32"
              height="32"
            ></rect>
          </g>
        </svg>
      ),
    },
  ];




  useGSAP(() => {
    // gsap code here...
    gsap.to(".animated-up-line", {
      x: "100%",
      scrollTrigger: {
        trigger: ".animated-up-line",
        scroller: "body",
        start: "top 70%",
        end: "top top",
        scrub: true,
      },
    });

    gsap.to("#animated-up-line2", {
      y: "100%",
      scrollTrigger: {
        trigger: "#animated-up-line2",
        scroller: "body",
        start: "top 50%",
        end: "top top",
        scrub: true,
      },
    });


    
if (window.innerWidth > 600) {
  gsap.from(".expertise-card", {
      y: "20%",
      opacity: 0,
      stagger: 0.2,
      scrollTrigger: {
          trigger: ".expertise-card",
          scroller: "body",
          start: "top 85%",
          end: "top top",
          
      },
  });
} else {
  const cards = document.querySelectorAll(".expertise-card");
  cards.forEach((card, index) => {
      gsap.from(card, {
          y: "20%",
          opacity: 0,
          scrollTrigger: {
              trigger: card,
              scroller: "body",
              start: "top 90%",
              end: "top top",
          },
      });
  });
}

    // <-- automatically reverted
  });
  return (
    <>
      <div
        className={`main-container relative flex justify-center  items-center  h-fit ${
          isDark ? "bg-[#131313]" : "bg-[#dadada]"
        }  flex flex-col`}
      >
        <div className="animated-cross-box  relative   flex justify-center items-center  h-[300px] my-[30px]  w-[300px] b mx-auto">
          <div
            className="animated-down-line w-[400px] h-[3px] rounded-2xl   absolute origin-top  -rotate-45   overflow-hidden "
            style={{
              background: isDark
                ? "linear-gradient(to right,black,purple,purple,purple,black)"
                : "linear-gradient(to right,#dadada,purple,purple,purple,#dadada)",
              transform: "rotate(45deg)",
              // boxShadow: "rgba(105, 10, 176, 0.3) 0px 0px 5px",
            }}
          >
            <div
              className={`animated-up-line  w-full h-full absolute  ${
                isDark ? "bg-[#242424]" : "bg-[#c0bdbd]"
              }`}
            ></div>
          </div>
          <div
            className="animated-down-line  w-[400px]  h-[3px]  rounded-2xl  absolute origin-top  -rotate-45   overflow-hidden "
            style={{
              background: isDark
                ? "linear-gradient(to right,black,purple,purple,purple,black)"
                : "linear-gradient(to right,#dadada,purple,purple,purple,#dadada)",
              transform: "rotate(-45deg)",
              // boxShadow: "rgba(105, 10, 176, 0.3) 0px 0px 5px",
            }}
          >
            <div
              className={`animated-up-line  w-full h-full absolute  ${
                isDark ? "bg-[#242424]" : "bg-[#c0bdbd]"
              }`}
            ></div>
          </div>
        </div>

        <div
          className={` expertise-container flex flex-col lg:w-[80%] lg:space-x-4 px-1 space-y-1 lg:space-y-0 lg:flex-row py-2`}
        >
          {cardsoptions.map((c, i) => (
            <ExpertiseCard
              key={i}
              heading={c.heading}
              description={c.description}
              svg={c.svg}
              number={i + 1}
            />
          ))}
        </div>

        <div className="arrow-icon z-50 flex flex-col items-center   justify-center w-full absolute -top-[5%] sm:-top-[6%] ">
          <svg
            width="5vmax"
            height="5vh"
            viewBox="0 0 1024 1024"
            className="icon -mb-3"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="#ffffff"
            stroke="#ffffff"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                fill="#832ac7"
              ></path>
            </g>
          </svg>
          <svg
            // width="vmax"
            height="10vh"
            
            viewBox="0 0 1024 1024"
            className="icon -my-8"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="#ffffff"
            stroke="#ffffff"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                fill="#832ac7"
              ></path>
            </g>
          </svg>
          
        </div>

        <div id="animation-for-aboutus" className="animated-down-line2 relative overflow-hidden "
          style={{
            width: "3px",
            borderRadius: "70%",
            margin: "30px  auto 30px auto",
            height: "40vh",
            background: "purple",

            // boxShadow: "rgba(105, 10, 176, 0.3) 0px 0px 5px",
          }}
        >
          <div
          id="animated-up-line2"
            className={`animated-up-line2 w-full h-full absolute  ${
              isDark ? "bg-[#242424]" : "bg-[#c0bdbd]"
            }`}
          ></div>
        </div>
      </div>
    </>
  );
}

export default ExpertisePage;
